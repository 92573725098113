import { ImageLoaderProps } from "next/image"
import { CSSProperties } from "react"
import { ComponentProps, forwardRef, memo } from "react"

import { useCachedMedia } from "../../hooks/use-cached-media"

type CachedImageProps = Omit<ComponentProps<"img">, "ref"> & {
  src?: string
  fallbackSrc?: string
  fill?: boolean
  _sizes?: string
  loader?: (props: ImageLoaderProps) => string
  priority?: boolean
  unoptimized?: boolean
  width?: number
  _height?: number
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down"
  alt?: string
}

/**
 * This component is a wrapper around images that caches them to avoid issues with `Cache-Control` headers not being set correctly by Next.js.
 * This uses the `useCachedMedia` hook that caches the image to local storage and retrieves it as a blob.
 */
export const CachedImage = memo(
  forwardRef<HTMLImageElement, CachedImageProps>(function CachedImage(
    {
      src,
      fallbackSrc,
      fill,
      _sizes,
      loader,
      priority,
      loading = "lazy",
      unoptimized,
      width,
      _height,
      objectFit,
      alt = "",
      ...props
    },
    ref
  ) {
    const cachedSrc = useCachedMedia(src)

    // If no source URL, return null (image not displayed)
    if (!src) return null

    // Prepare image properties, including styles and attributes
    const imageProps = {
      ref,
      loading: priority ? "eager" : loading,
      style: {
        ...(fill && {
          position: "absolute" as const,
          height: "100%",
          width: "100%",
          inset: 0,
        }),
        ...(objectFit && { objectFit }),
      } as CSSProperties,
      ...props,
    }

    // Render the image directly if it's a blob
    if (src.startsWith("blob:")) {
      return <img alt={alt} src={src} {...imageProps} />
    }

    const absoluteUrl = src.startsWith("http") ? src : new URL(src, window.location.origin).toString()
    const effectiveWidth = width || (fill ? 1024 : 384)
    // Transform the URL with the `spatialCdnImageLoader` function (if provided)
    const transformedUrl =
      !unoptimized && loader ? loader({ src: absoluteUrl, width: effectiveWidth, quality: 75 }) : absoluteUrl
    const finalSrc = cachedSrc || fallbackSrc || transformedUrl

    return <img alt={alt} src={finalSrc} {...imageProps} />
  })
)
