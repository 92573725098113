import { useCallback, useEffect, useState } from "react"

interface UseCachedMediaOptions {
  cacheName?: string
  staleDuration?: number
  cleanupInterval?: number
  maxAge?: number
}

/**
 * This hook caches media (images and videos) to avoid issues with `Cache-Control` headers not being set correctly by Next.js.
 * It uses local storage to cache the media and retrieves it as a blob.
 *
 * @param mediaUrl - The URL of the media to cache.
 * @param options - The options for the cache: `cacheName`, `staleDuration`, `cleanupInterval`, and `maxAge`.
 * @returns The URL of the cached media.
 */
export function useCachedMedia(
  mediaUrl: string | undefined,
  {
    cacheName = "media-cache",
    staleDuration = 7 * 24 * 60 * 60 * 1000, // 7 days
    cleanupInterval = 30 * 24 * 60 * 60 * 1000, // 30 days
    maxAge = 90 * 24 * 60 * 60 * 1000, // 90 days
  }: UseCachedMediaOptions = {}
): string {
  const [blobUrl, setBlobUrl] = useState<string>(mediaUrl || "")

  /**
   * Cleans up the cache of expired media
   */
  const cleanupCache = useCallback(async () => {
    const lastCleanup = localStorage.getItem(`${cacheName}-last-cleanup`)
    const now = Date.now()

    if (!lastCleanup || now - parseInt(lastCleanup) > cleanupInterval) {
      const cache = await caches.open(cacheName)
      const keys = await cache.keys()

      for (const request of keys) {
        const cachedResponse = await cache.match(request)
        const cachedDate = new Date(cachedResponse?.headers.get("date") || "")
        if (cachedResponse && now - cachedDate.getTime() > maxAge) {
          await cache.delete(request)
        }
      }

      localStorage.setItem(`${cacheName}-last-cleanup`, now.toString())
    }
  }, [cacheName, cleanupInterval, maxAge])

  /**
   * Creates a blob URL from a response
   */
  const createBlobUrl = useCallback(async (response: Response) => {
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    setBlobUrl(url)
    return url
  }, [])

  // If mediaUrl is undefined or empty, clears the blobUrl (which is the cached media URL) and exits early,
  // avoiding any unnecessary fetch or cache operations.
  useEffect(() => {
    if (!mediaUrl) {
      setBlobUrl("")
      return
    }

    const controller = new AbortController()
    let isMounted = true

    /**
     * Main async function handling fetching media, caching, revalidation, and cleanup.
     */
    const fetchMediaWithCache = async () => {
      try {
        const cache = await caches.open(cacheName)
        const cachedResponse = await cache.match(mediaUrl)

        // If the media is cached, check if it is stale
        if (cachedResponse) {
          const cachedDate = new Date(cachedResponse.headers.get("date") || "")
          const isStale = Date.now() - cachedDate.getTime() > staleDuration

          // If the response is not stale, return the cached response
          if (!isStale) {
            await createBlobUrl(cachedResponse)
            return
          }

          // If the response is stale, revalidate it
          const etag = cachedResponse.headers.get("etag")
          const lastModified = cachedResponse.headers.get("last-modified")

          fetch(mediaUrl, {
            method: "GET",
            cache: "no-cache",
            headers: {
              ...(etag ? { "If-None-Match": etag } : {}),
              ...(lastModified ? { "If-Modified-Since": lastModified } : {}),
            },
            signal: controller.signal,
          })
            .then(async (revalidationResponse) => {
              if (!isMounted) return

              // If the revalidation response is not modified, return the cached response.
              // Otherwise, update the cache with the revalidated response and create a blob URL.
              if (revalidationResponse.status === 304) {
                const headers = new Headers(cachedResponse.headers)
                headers.set("date", new Date().toUTCString())
                await cache.put(
                  mediaUrl,
                  new Response(cachedResponse.body, {
                    status: cachedResponse.status,
                    statusText: cachedResponse.statusText,
                    headers,
                  })
                )
              } else if (revalidationResponse.ok) {
                const headers = new Headers(revalidationResponse.headers)
                headers.set("date", new Date().toUTCString())
                const newResponse = revalidationResponse.clone()
                await cache.put(
                  mediaUrl,
                  new Response(revalidationResponse.clone().body, {
                    status: revalidationResponse.status,
                    statusText: revalidationResponse.statusText,
                    headers,
                  })
                )
                await createBlobUrl(newResponse)
              }
            })
            .catch((error) => {
              console.debug("[CachedMedia] Background revalidation error:", error)
            })

          return
        } else {
          // If the media is not cached, fetch it from the network
          const response = await fetch(mediaUrl, {
            method: "GET",
            cache: "no-cache",
            signal: controller.signal,
          })

          if (!isMounted) return

          // If the response is successful, cache it and create a blob URL
          if (response.ok) {
            const headers = new Headers(response.headers)
            headers.set("date", new Date().toUTCString())
            const etag = response.headers.get("etag")
            if (etag) headers.set("etag", etag)
            const lastModified = response.headers.get("last-modified")
            if (lastModified) headers.set("last-modified", lastModified)

            const responseToCache = response.clone()
            await cache.put(
              mediaUrl,
              new Response(response.clone().body, {
                status: response.status,
                statusText: response.statusText,
                headers,
              })
            )
            await createBlobUrl(responseToCache)

            // Cache cleanup after successful fetch
            await cleanupCache()
          } else {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error instanceof Error && error.name !== "AbortError") {
            console.error("[CachedMedia] Error fetching media:", error)
          }
          setBlobUrl(mediaUrl || "")
        }
      }
    }

    void fetchMediaWithCache()

    // Cleanup on unmount
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [mediaUrl, cacheName, staleDuration, createBlobUrl, cleanupCache])

  // Clean up Blob URL on unmount
  useEffect(() => {
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl)
      }
    }
  }, [blobUrl])

  return blobUrl
}
